<template>
  <div class="home">
    <!-- home block -->
    <div v-append-html="pageContent"/>
  </div>
</template>

<script>
// @ is an alias to /src
import CmsContent from '@/components/CmsContent'
import { isMobile } from '@/helpers/utils'
import MetaHelper from '@/helpers/Meta'
import { manageInternalUrl } from '@/helpers/routeHelpers'

function getHrefLang ($store) {
  const countries = $store.getters['ui/getCountries']
  const hrefLang = []
  if (countries) {
    for (const country in countries) {
      countries[country].forEach(item => {
        hrefLang.push({
          rel: 'alternate',
          hreflang: item.locales[0].language + '_' + item.country,
          href: item.base_url
        })
      })
    }
  }
  return hrefLang
}

export default {
  name: 'home',
  components: {
    CmsContent
    /* StaticHome, */
  },
  head () {
    const metaHelper = new MetaHelper(this.$store)
    const title = this.$store.state.cms.meta.title || metaHelper.getTitle()
    const description = this.$store.state.cms.meta.description || metaHelper.getDescription()
    const keywords = this.$store.state.cms.meta.keywords || metaHelper.getKeyword()
    const hrefLang = getHrefLang(this.$store)

    return {
      title: title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: description
      },
      {
        vmid: 'title',
        name: 'title',
        content: this.$store.state.cms.meta.title || metaHelper.getTitle()
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: keywords
      }],
      link: hrefLang
    }
  },
  mounted () {
    if (process.client) {
      // go to forgot passord
      if (this.$route.query.forgotpassword && this.$route.query.forgotpassword === 'true') {
        let componentToLoad = import('theme/components/Auth/ForgotPassword')
        this.renderComponent(componentToLoad, { headerTitle: this.$t('forgot-password') })
      }
      // go to login
      if (this.$route.query.login && this.$route.query.login === 'true') {
        let componentToLoad = import('theme/components/Auth/Login')
        this.renderComponent(componentToLoad, { headerTitle: this.$t('login') })
      }
    }
  },
  computed: {
    pageContent () {
      return this.$store.getters['cms/getContentPage']
    },
    hrefLang () {
      let countries = this.$store.getters['ui/getCountries']
      if (countries) {
        let finalArr = []
        let areas = Object.entries(countries)
        let elements = areas.map((element) =>
          element[1].map(item => ({
            rel: 'alternate',
            hreflang: item.locales[0].language + '_' + item.country,
            href: item.base_url
          })))
        elements.forEach(element => {
          finalArr.push(...element)
        })
        this.$store.commit('ui/setHrefLang', finalArr)
      }
      return ''
    }
  },
  asyncData ({ store, redirect }) {
    return store.dispatch('cms/getPage', store.getters['storeConfig/homePage'])
      .then(() => {
        // dati strutturati
        let jsonLD = []
        if (store.state.storeConfig.config.seo_markup_seller &&
            store.state.storeConfig.config.seo_markup_seller.show === 'only_home_page' &&
            store.state.storeConfig.config.seo_markup_seller.json) {
          const organization = {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            'name': 'Aeffe Retail S.p.A.',
            'telephone': '+39 02 94758110',
            'address': {
              '@type': 'PostalAddress',
              'addressLocality': 'San Giovanni in Marignano',
              'addressRegion': 'Rimini',
              'streetAddress': 'Via Delle Querce 51',
              'postalCode': '47842'
            },
            // 'image': 'https://preprod.ecomv.albertaferretti.com/media/wysiwyg/transactional_email/Alberta_ferretti_mail.png',
            'url': 'https://www.philosophyofficial.com/'
          }
          jsonLD.push(organization)
        }
        // speakable default
        if (store.state.storeConfig.config.seo_markup_speakable &&
            store.state.storeConfig.config.seo_markup_speakable.json_page) {
          jsonLD.push(store.state.storeConfig.config.seo_markup_speakable.json_page)
        }
        store.commit('ui/setJsonLD', jsonLD)
        store.commit('ui/setCanonical', `${process.env.VUE_APP_BASE_PATH}/${store.state.ui.urlCode}/`)
      })
      .catch(() => {
        // redirect({ name: 'page-not-found', params: { lang: store.state.ui.storeViewCode } })
      })
  },
  methods: {
    renderComponent (component, props) {
      if (!isMobile()) {
        // Top
        this.$store.dispatch('ui/loadTopActiveComponent', { component })
      } else {
        // Left
        this.$store.dispatch('ui/loadLeftActiveComponent', { component, props })
      }
    },
    managerCMSUrl () {
      const item = document.querySelectorAll('.home [href]')
      item.forEach(link => {
        link.addEventListener('click', function (e) {
          e.preventDefault()
          let href = $(e.currentTarget).attr('href')
          let url = new URL(href)
          return manageInternalUrl(e, url)
        })
      })
    }
  }
}
</script>
